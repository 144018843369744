import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Row, Col } from "../components/page-components/grid";
import MD from "gatsby-custom-md";
import "../style/portfolio-singlepage.less";

const components = {
    row: Row,
    col: Col
};

export default function({ data, pageContext }) {
    const next = pageContext.next
      ? {
           url: pageContext.next.fields.slug,
           title: pageContext.next.frontmatter.title,
        }
      : null
   const prev = pageContext.prev
      ? {
           url: pageContext.prev.fields.slug,
           title: pageContext.prev.frontmatter.title,
        }
      : null
    return (
        <Layout>
            <SEO
                title={data.markdownRemark.frontmatter.title}
                description={data.markdownRemark.frontmatter.description}
                image={data.markdownRemark.frontmatter.image.publicURL}
            />
            <div className="container">
                <article className="portfolio-post">
                    <div className="head text-primary">
                        <h1>{data.markdownRemark.frontmatter.title}</h1>
                        <p className="post-date">
                            {data.markdownRemark.frontmatter.date}
                        </p>
                    </div>
                    <div className="content row flex">
                        <div className="col s12">
                            <MD
                                components={components}
                                htmlAst={data.markdownRemark.htmlAst}
                            />
                        </div>
                    </div>
                </article>
            </div>

            <div  className="row flex center pagination-header">
                <span>
                    <h6>
                        <Link to="/neler-yaptik"><svg xmlns="http://www.w3.org/2000/svg" height="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
</svg></Link>
                    </h6>
                </span>
            </div>

            <div className="container row pagination-links">
                    <div className="col s6">
                        {prev && (
                            <Link to={prev.url}>
                                <span>Önceki Proje</span>
                                <h6>{prev.title}</h6>
                            </Link>
                        )}
                        <br/>
                    </div>
                    
                    <div className="col s6 right">
                        {next && (
                            <Link to={next.url}>
                                <span>Sonraki Proje</span>
                                <h6>{next.title}</h6>
                            </Link>
                        )}
                        <br/>
                    </div>
                </div>
        </Layout>
    );
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            htmlAst
            id
            fields {
                slug
              }
            frontmatter {
                title
                date(fromNow: true, locale: "tr")
                description
                image {
                    publicURL
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            srcSet
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
